import { styled } from '@mui/system'

const shouldForwardProp = (prop) => !['columnLayout'].includes(prop)

export const PriceWrapper = styled('div', { shouldForwardProp })`
	display: flex;
	align-items: ${({ columnLayout }) => (columnLayout ? 'flex-start' : 'center')};
	flex-wrap: wrap;
	flex-direction: ${({ columnLayout }) => (columnLayout ? 'column' : 'row')};
`

export const DefaultPrice = styled('span', { shouldForwardProp })`
	font-family: ${({ theme }) => theme.typography.fontFamily};
	font-weight: ${({ theme }) => theme.typography.fontWeight};
	display: flex;
	align-items: center;
	flex-basis: auto;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
`

export const BasePrice = styled('span', { shouldForwardProp })`
	color: #aaa;
	text-decoration: line-through;
	flex-basis: auto;
	margin-right: ${({ columnLayout }) => (columnLayout ? 0 : '10px')};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
`
export const MiddlePrice = styled('span', { shouldForwardProp })`
	color: ${({ theme }) => theme.palette.grey.main};
	flex-basis: auto;
	text-decoration: line-through;
	margin-right: ${({ columnLayout }) => (columnLayout ? 0 : '10px')};
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
`

export const SpecialPrice = styled('span', { shouldForwardProp })`
	font-weight: ${({ theme }) => theme.typography.fontWeight};
	flex-basis: auto;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
	${({ theme }) => theme.breakpoints.down('md')} {
		flex-basis: 100%;
	}
`

export const DiscountPercentage = styled('span', { shouldForwardProp })`
	color: ${({ theme }) => theme.palette.common.white};
	font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
	font-weight: ${({ theme }) => theme.typography.fontWeight};
	margin: ${({ columnLayout }) => (columnLayout ? '0' : '0 10px 0 0')};
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	background-color: var(--color-pink);
	letter-spacing: 0;
	line-height: 1;
	padding: 2.5px 5px;
`
export const FromLabel = styled('div', { shouldForwardProp })`
	margin: ${({ columnLayout }) => (columnLayout ? '0' : '0 5px 0 0')};
`

export const ToLabel = styled('div', { shouldForwardProp })`
	margin: ${({ columnLayout }) => (columnLayout ? '0' : '0 5px')};
`

export const OldPrice = styled('span', { shouldForwardProp })`
	color: ${({ theme }) => theme.palette.grey.main};
	flex-basis: auto;
	text-decoration: line-through;
	margin-right: ${({ columnLayout }) => (columnLayout ? 0 : '10px')};
`
